import React from "react";
import { Divider, notification, Button, Modal, Tag } from "antd";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { Row, Col } from 'react-bootstrap';
import { FaTrashAlt, FaRegEdit, FaTrashRestoreAlt } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  listVideoTutorial,
  deleteVideoTutorial,
  resetVideoTutorial
} from "../../../appRedux/actions/videoTutorial";

const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

class ListVideoTutotial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      videoTutotialList: [],
      pagination: {
        current: 1,
        pageSize: 10
      }
    }

    this.columns = [{
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      sorter: (a, b) => a.type.length - b.type.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Sub Type',
      dataIndex: 'subType',
      key: 'subType',
      width: 150,
      sorter: (a, b) => a.subType.length - b.subType.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Video URL',
      dataIndex: 'videoUrl',
      key: 'videoUrl',
      width: 150,
      render: (videoUrl) => (
        <span
          className="gx-link"
          onClick={(e) => { this.onVideoTutorialUrl(videoUrl, e) }}>
          {videoUrl}
        </span>
      ),
    }, {
      title: 'Video For',
      dataIndex: 'videoFor',
      key: 'videoFor',
      sorter: (a, b) => a.videoFor - b.videoFor,
      sortDirections: ['descend', 'ascend'],
      render: (videoFor) => {
        return (<Tag color={videoFor === "Desktop" ? "magenta" : "green"}>
          {videoFor}
        </Tag>)
      }
    }, {
      title: 'Status',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      sorter: (a, b) => a.isDeleted - b.isDeleted,
      sortDirections: ['descend', 'ascend'],
      render: (isDeleted) => {
        return (<Tag color={isDeleted ? "warning" : 'processing'}>
          {isDeleted ? 'Deleted' : 'Active'}
        </Tag>)
      },
      filters: [
        {
          text: 'Active',
          value: false
        },
        {
          text: 'Deleted',
          value: true
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.isDeleted === value
    }, {
      title: 'Modified By',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      width: 120,
      sorter: (a, b) => a.modifiedBy.firstName?.length - b.modifiedBy.firstName?.length,
      sortDirections: ['descend', 'ascend'],
      render: (modifiedBy) => {
        return (<span>{modifiedBy?.firstName} {modifiedBy?.lastName}</span>)
      }
    }, {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      width: 150,
      key: 'updatedAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (updatedAt) => { return (<span>{moment(updatedAt).format('MM-DD-YYYY hh:mm a')}</span>) }
    }, {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <span>
          <span className="gx-link">
            {<Link to={`/video-tutorial/edit/${record._id}`}><FaRegEdit className="icon-size gx-link" /></Link>}
          </span>
          <Divider type="vertical" />
          {!record.isDeleted ?
            <span title="Delete" className="gx-link delete-color" onClick={(e) => { this.onConfirmDelete(record); }}>
              <FaTrashAlt className="icon-size" />
            </span>
            :
            <span title="Restore" className="gx-link delete-color" onClick={(e) => { this.onConfirmRestore(record); }}>
              <FaTrashRestoreAlt className="icon-size" />
            </span>
          }
        </span>
      ),
    }]

  };


  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.videoTutorial) {
      if (nextProps.videoTutorial.messageId === 204) {
        return {
          videoTutotialList: nextProps.videoTutorial.lists.data,
          pagination: {
            current: nextProps.videoTutorial.lists.current,
            pageSize: nextProps.videoTutorial.lists.pageSize,
            total: nextProps.videoTutorial.lists.totalCount
          }
        }
      } else if (nextProps.videoTutorial.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.videoTutorial.alertMessage
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetVideoTutorial();
      } else if (nextProps.videoTutorial.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.videoTutorial.alertMessage
        });
        nextProps.resetVideoTutorial();
      }
      return true;
    }
    return null;
  }

  onConfirmDelete(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Delete',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete ' + record.type + ' "' + record.subType + '"?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onDeleteRecord
      });
    })
  }

  onDeleteRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: true
    }
    this.props.deleteVideoTutorial(authUser.token, data);
  }

  onConfirmRestore(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Restore',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to restore ' + record.type + ' "' + record.subType + '"?',
        okText: 'Restore',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onRestoreRecord
      });
    })
  }

  onRestoreRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: false
    }
    this.props.deleteVideoTutorial(authUser.token, data);
  }


  onVideoTutorialUrl = (url, e) => {
    window.open(url, '_blank');
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      if (pageInfo.isDeleted && pageInfo.isDeleted.length > 0) {
        inputJson.isDeleted = pageInfo.isDeleted[0] ? 'TRUE' : 'FALSE';
      }
      this.props.listVideoTutorial(authUser.token, inputJson);
    }
  }

  onChange = e => {
    if (e.type === 'click') {
      const { pagination } = this.state;
      this.fetch({ pagination });
    }
  };

  render() {
    const { loader } = this.props.videoTutorial;
    const { pagination, videoTutotialList } = this.state;
    return (
      <Auxiliary>
        <PageHeader title="Video Tutorials" navigateTo="/video-tutorial/add" linkName="Add Video Tutorial" />
        <>
          <Row className="row-margin2">
            <Col>
              <Table
                className="gx-table-responsive"
                columns={this.columns}
                dataSource={videoTutotialList}
                rowKey={record => record._id}
                pagination={{
                  ...pagination,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  itemRender: itemRender,
                  size: 'small'
                }}
                loading={loader}
                size={'middle'}
                onChange={this.handleTableChange}
              />
            </Col>
          </Row>
        </>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    videoTutorial: state.videoTutorial
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ listVideoTutorial, deleteVideoTutorial, resetVideoTutorial }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(ListVideoTutotial);
