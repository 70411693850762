import React, { Component } from "react";
import { Button, Card, Form, Input, Radio, Select, notification } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';

import CircularProgress from "components/CircularProgress";
import Auxiliary from "util/auxiliary";
import PageHeader from "components/PageHeader";

import {
  addVideoTutorial,
  updateVideoTutorial,
  resetVideoTutorial
} from "./../../../appRedux/actions/videoTutorial";

const FormItem = Form.Item;
const { Option } = Select;

class AddVideoTutotial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editId: null,
      selected_type: null,
      videoFor: "Desktop",
      types: [{
        name: 'E-commerce'
      }, {
        name: 'Personalization'
      }, {
        name: 'Contributor'
      }],
      sub_types: [{
        type: 'E-commerce',
        name: 'Home Slider 1'
      },
      {
        type: 'E-commerce',
        name: 'Home Slider 2'
      }, {
        type: 'E-commerce',
        name: 'Home Slider 3'
      }, {
        type: 'E-commerce',
        name: 'How To Order'
      }, {
        type: 'Personalization',
        name: 'Events'
      }, {
        type: 'Personalization',
        name: 'Greetings'
      }, {
        type: 'Personalization',
        name: 'Personalized Message'
      }, {
        type: 'Personalization',
        name: 'Personalization Tips'
      }, {
        type: 'Personalization',
        name: 'Recipient Interest'
      }, {
        type: 'Personalization',
        name: 'Recipient Profile'
      }, {
        type: 'Contributor',
        name: 'GIG Acceptance'
      }]
    }

    this.formRef = React.createRef();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      const { authUser } = this.props.auth;
      const INSTANCE = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '.concat(authUser.token)
        }
      });

      let inputJSON = JSON.stringify({
        _id: this.props.match.params.id
      })

      INSTANCE.post("video-tutorial/get-by-id", inputJSON)
        .then((response) => {
          if (response.data.messageId === 200) {
            this.formRef.current.setFieldsValue({
              type: response.data.data.type,
              subType: response.data.data.subType,
              videoUrl: response.data.data.videoUrl,
              videoFor: response.data.data.videoFor
            });
            this.setState({
              editId: this.props.match.params.id
            })
          }
        })
        .catch((error) => {
        })
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.videoTutorial) {
      if (nextProps.videoTutorial.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.videoTutorial.alertMessage
        });
        nextProps.resetVideoTutorial();
      } else if (nextProps.videoTutorial.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.videoTutorial.alertMessage
        });
        nextProps.resetVideoTutorial();
      }
      return true;
    }
    return null;
  }

  onFinish = values => {
    const { authUser } = this.props.auth;
    const { editId } = this.state;
    let inputJSON = values;
    if (editId) {
      inputJSON._id = editId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.updateVideoTutorial(authUser.token, inputJSON);
      this.props.history.goBack();
    } else {
      inputJSON.createdBy = authUser.user.userId;
      inputJSON.modifiedBy = authUser.user.userId;
      this.props.addVideoTutorial(authUser.token, inputJSON);
    }
  };

  onTutorialTypeSelect(value) {
    this.setState({
      selected_type: value
    })
  }

  render() {
    const { loader } = this.props.videoTutorial;
    const { editId, types, sub_types, selected_type } = this.state;
    return (
      <Auxiliary>
        <PageHeader title={editId ? "Edit Video Tutorial" : "Add Video Tutorial"} />
        <Row className="row-margin2">
          <Col>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> :
              <Card>
                <Form
                  name="videoTutorialForm"
                  onFinish={this.onFinish}
                  layout="vertical"
                  ref={this.formRef}
                  initialValues={{
                    videoFor: this.state.videoFor,
                  }}
                >

                  <FormItem label="Tutorial Type" name="type" rules={[{ required: true, message: 'Please select tutorial type.' }]}>
                    <Select placeholder="Select Tutorial Type" onSelect={(value) => this.onTutorialTypeSelect(value)}>
                      {types.map(item => (
                        <Option key={item.name}>{item.name}</Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem label="Tutorial Sub Type" name="subType" rules={[{ required: true, message: 'Please select tutorial sub type.' }]}>
                    <Select placeholder="Select Tutorial Sub Type">
                      {sub_types.map(item => (
                        selected_type === item.type ? <Option key={item.name}>{item.name}</Option> : null
                      ))}
                    </Select>
                  </FormItem>

                  <FormItem label="Video URL" rules={[{ required: true, message: 'Please enter video url.' }]} name="videoUrl">
                    <Input placeholder="Video URL" />
                  </FormItem>

                  <FormItem label="Video For" name="videoFor" >
                    <Radio.Group>
                      <Radio.Button value="Desktop">Desktop</Radio.Button>
                      <Radio.Button value="Mobile">Mobile</Radio.Button>
                    </Radio.Group>
                  </FormItem>

                  <FormItem>
                    <Button loading={loader} className="gx-mb-0" type="primary" htmlType="submit">
                      {editId ? "Update" : "Submit"}
                    </Button>
                  </FormItem>
                </Form>
              </Card>
            }
          </Col>
        </Row>
      </Auxiliary>
    );
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    videoTutorial: state.videoTutorial
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ addVideoTutorial, updateVideoTutorial, resetVideoTutorial }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AddVideoTutotial);
